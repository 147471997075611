<template>
  <div class="page-wrapper">
    <main class="contact">
      <section class="intro">
        <div class="intro-content">
          <h1 class="h1 narrow">Questions? Comments? Feedback? Please get in touch!</h1>
          <h1 class="h2 wide">Questions? Comments? Feedback? Please get in touch!</h1>
          <hr>
          <p class="p2">Whether you’re looking for ways to support and participate, or have an idea to share, we want to hear from you. Please fill-out this contact form and we’ll respond promptly!</p>
        </div>
      </section>

      <section class="form-wrapper">
        <div class="form-card">
          <form id="contactform" @submit.prevent="validateForm">
            <div>
              <label for="fullname">Name <span class="alert">{{ errName }}</span></label>
              <input type="text" name="fullname" id="fullname" v-model="fullname" @change="testName" :class="{ error: errName, validated:  fullname }">
            </div>
            <div>
              <label for="emailaddress">Email Address <span class="alert">{{ errEmail }}</span></label>
              <input type="email" name="emailaddress" id="emailaddress" v-model="emailaddress" @change="testEmail" :class="{ error: errEmail, validated: emailaddress }">
            </div>
            <div>
              <label for="phone">Phone (Optional)</label>
              <input type="tel" name="phone" id="phone" v-model="phone" :class="{ validated: phone }">
            </div>
            <div>
              <label for="topic">Choose Topic <span class="alert">{{ errTopic }}</span></label>
              <select name="topic" id="topic" v-model="topic" @change="testTopic" :class="{ error: errTopic, validated: topic }">
                <option value="" selected disabled>Select Topic</option>
                <option value="Mentoring">Mentoring</option>
                <option value="Scholarships">Scholarships</option>
                <option value="Balanced Body Education">Balanced Body Education</option>
                <option value="General">General</option>
              </select>
            </div>
            <div>
              <label for="message">Message <span class="alert">{{ errMessage }}</span></label>
              <textarea name="message" id="message" v-model="message" maxlength="1000" @change="testMessage" :class="{ error: errMessage, validated: message }"></textarea>
            </div>
            <div>
              <span class="submit-alert" v-if="alert">{{ alert }}</span>
              <input type="submit" value="Send Message" :class="{ disabled: disabled }" :disabled="disabled">
            </div>
          </form>
        </div>
      </section>
    </main>
    <div class="bg"><span class="triangle"/></div>
  </div>
</template>

<script>
  export default {
    name: "Contact",

    metaInfo: {
      title: "Questions? Please get in touch!",
      titleTemplate: "%s | Diversity in Pilates",
      meta: [
        {
          name: "description",
          content: "Whether you're looking for ways to support and participate, or have an idea to share, we want to hear from you. Please fill-out this contact form and we'll respond promptly!"
        }
      ]
    },

    data() {
      return {
        alert: "",
        fullname: "",
        emailaddress: "",
        phone: "",
        topic: "",
        message: "",
        errName: "",
        errEmail: "",
        errTopic: "",
        errMessage: "",
        disabled: false,
        error: false
      };
    },

    methods: {
      validateForm() {
        this.errName = "";
        this.errEmail = "";
        this.errTopic = "";
        this.errMessage = "";
        if ( !this.fullname ) {
          this.errName = "The name field is required."
        }
        if ( this.fullname.length > 35 ) {
          this.errName = "Too many characters, please try again."
        }
        if ( !this.emailaddress ) {
          this.errEmail = "The email address field is required."
        }
        if ( this.emailaddress.length > 45 ) {
          this.errEmail = "Too many characters, please try again."
        }
        if ( !this.topic ) {
          this.errTopic = "The topic field is required."
        }
        if ( !this.message ) {
          this.errMessage = "The message field is required."
        }
        if ( this.message.length > 1000 ) {
          this.errMessage = "Too many characters, please try again."
        }
        if ( !this.errName && !this.errEmail && !this.errTopic && !this.errMessage ) {
          this.submitForm();
        }
      },

      testName() {
        return this.fullname === "" ? this.errName = "The name field is required." : this.errName = "";
      },

      testEmail() {
        if ( this.emailaddress === "" ) {
          return this.errEmail = "The email field is required.";
        } else if (!this.validEmail(this.emailaddress)) {
          return this.errEmail = "Please enter a valid email address.";
        } else {
          return this.errEmail = "";
        }
      },

      testTopic() {
        return this.topic === "" ? this.errTopic = "The topic field is required." : this.errTopic = "";
      },

      testMessage() {
        return this.message === "" ? this.errMessage = "The message field is required." : this.errMessage = "";
      },

      validEmail(email) {
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },

      submitForm() {
        this.alert = "Sending...";
        const url = 'https://bb-website.azurewebsites.net/wp-json/contact-form-7/v1/contact-forms/5234/feedback'
        var formHeaders = new Headers();
        var formElement = document.querySelector("#contactform");
        var formdata = new FormData(formElement);
        var requestOptions = {
          method: 'POST',
          headers: formHeaders,
          body: formdata
        };

        fetch(url, requestOptions)
        .then((response) => {
          this.response = response;
          if (response.status !== 200) {
            throw Error((this.alert = 'POST error, status code: ' + response.status));
          }
          this.disabled = true;
          this.alert = 'Thank you for your message!';
          return this.message;
        })
        .catch((error) => {
          this.alert = error;
          return error;
        });
      }
    }
  }
</script>

<style scoped>
.narrow {
  display: block;
}

.wide {
  display: none;
}

.intro {
  margin-bottom: 50px;
}

.intro-content {
  padding: 35px 34px 35px 32px;
  max-width: 520px;
  margin: 0 auto;
}

hr {
  border: none;
  border-top: 4px dotted #9EB675;
  margin: 40px 0 20px;
  width: 158px;
}

.form-card {
  width: 100%;
  max-width: 375px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
  background-color: #fff;
  overflow: hidden;
  padding: 50px 28px;
  margin: 0 auto;
}

label {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 11rem;
  line-height: 1.18;
  text-transform: uppercase;
  color: #555658;
  margin-bottom: 5px;
}

input,
textarea,
select {
  background: #fff;
  border: 1px solid #919191;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  margin-bottom: 18px;
  font-size: 16rem;
  line-height: 1.19;
  font-weight: 400;
  padding: 0 10px;
}

textarea {
  height: 214px;
  padding: 10px;
}

input[type=submit] {
  background: #16a87c;
  font-style: normal;
  font-weight: 700;
  font-size: 16rem;
  line-height: 1.19;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  border: 1px solid #16a87c;
  transition: 0.3s;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #13906a;
}

.disabled {
  background-color: #cacaca !important;
  border: 1px solid #cacaca !important;
  cursor: default !important;
}

.error {
  border: 1px solid red !important;
}

.validated {
  border: 1px solid #13906a !important;
}

span.alert {
  font-size: 10rem;
  font-style: italic;
  color: red;
  text-transform: none;
  display: inline-block;
  padding-left: 10px;
}

span.submit-alert {
  font-size: 12rem;
  font-style: italic;
  color: gray;
  text-transform: none;
  display: inline-block;
  padding-bottom: 10px;
}

@media only screen and (min-width: 850px) {
  .narrow {
    display: none;
  }

  .wide {
    display: block;
  }

  .contact {
    display: flex;
    justify-content: center;
    max-width: 875px;
    margin: 50px auto 0;
  }

  .page-wrapper {
    position: relative;
  }

  .bg {
    background-color: var(--warm1);
    position: absolute;
    top: 350px;
    left: 0;
    width: 100%;
    height: 450px;
    overflow: hidden;
  }

  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: max(268px, 60vw) 0 0 max(324px, 70vw);
    border-color: transparent transparent transparent var(--warm2);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .intro {
    width: 52%;
    background-color: #fff;
  }

  .form-wrapper {
    width: 48%;
  }

  .form-card {
    position: relative;
    z-index: 10;
  }

  .intro-content {
    padding-top: 0;
  }

  hr {
    margin: 50px 0 20px;
  }
}
</style>
